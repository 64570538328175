import { Box, Heading } from "@chakra-ui/react";

export const Reservas = () => {
  return (
    <section className="chilloutbackground">
      <Box p={"40px"}>
        <Heading as="h2" size="xl" mb={"15px"}>
          Reservas
        </Heading>
        <iframe
          title="Reservas"
          src="https://www.covermanager.com/reserve/module_restaurant/bibo-tarifa/spanish/?template=trans-black"
          frameborder="0"
          height="2000px"
          width="100%"
        ></iframe>
      </Box>
    </section>
  );
};

import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  chakra,
  GridItem,
  Image,
  Link,
  SimpleGrid,
  useColorModeValue,
  Heading,
  Text,
} from "@chakra-ui/react";
import "./Wallylopez.css";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


export const Wallylopez = forwardRef(({ onBackClick }, ref) => {
  useEffect(() => {
    localStorage.setItem("index", 2);
  });
  return (
    <section className="wallybackground">
      <Box>
        <Box
          // borderWidth="1px"
          // borderRadius="lg"
          overflow="hidden"
          w="100%"
          bgImage={"/assets/bannerNayeli.jpg"}
          color="white"
          height="500px"
          className="shadow"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Image
            w="100%"
            style={{ height: "fit-content", maxHeight: "300px",maxWidth: "400px"}}
            src="/assets/logoNayeli.png"
          />
        </Box>

        <Box p="15px">
          {/* <SimpleGrid
            columns={[1, null, 1, 1, 1]}
            spacing="10px"
            p="10px"
            alignItems="center"
          > */}
          {/* <Box>
              <Text as="cite">
                {" "}
                Nayeli (Origen Americano) <br></br>
                Significa Te Quiero <br></br> <br></br>
              </Text>
            </Box>{" "} */}
          <Box
            bg="#fff"
            borderRadius="1em"
            padding="20px"
            // marginLeft="40px"
            w="100%"
            color="black"
          >
            <Text fontSize="xl" style={{ textAlign: "center" }}>
              Desde el 10 de abril y hasta final de temporada los domingo serán
              de Wally López. El DJ nos trae a Bibo Tarifa los mejores DJs cada
              jornada para vivir al atardecer a lo grande. La música acompañará
              uno de los momentos más especiales, mágicos y relajantes que
              caminará con diferentes melodías musicales que se irán acompasando
              al ritmo de la caída del sol.
              <br></br>
              <br></br>
              Wally López te acompañará personalmente un domingo al mes para que
              puedas vivir una sesión llena de emoción.
            </Text>
          </Box>
          {/* </SimpleGrid> */}
        </Box>
        <Box paddingLeft="25px" marginTop="15px">
          <SimpleGrid columns={[1, null, 2, 2, 4]} spacing="10px" p="10px">
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "30px",
              }}
            >
              <div className="album">
                <span className="vinyl"></span>
                <span className="cover cover1"></span>
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "30px",
              }}
            >
              <div className="album">
                <span className="vinyl"></span>
                
                <span className="cover cover2"></span>
              </div>
            </Box>

   
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "30px",
              }}
            >
              <div className="album">
                <span className="vinyl"></span>
                <span className="cover cover3"></span>
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "30px",
              }}
            >
              <div className="album">
                <span className="vinyl"></span>
                <span className="cover cover4"></span>
              </div>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </section>
  );
});

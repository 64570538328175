import { Box, Image, Link, SimpleGrid, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import "./Marenostrum.css";
import React, { useEffect } from "react";
import SmallTicket from "../../Components/Ticket/SmallTicket";

export const Marenostrum = () => {
  useEffect(() => {
    localStorage.setItem("index", 1);
  });
  return (
    <section className="marenostrumbackground">
      <Box>
        <Box
          // borderWidth="1px"
          // borderRadius="lg"
          overflow="hidden"
          w="100%"
          bgImage={"/assets/bannerMarenostrum.jpg"}
          color="white"
          height="500px"
          className="shadow"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Image
            w="100%"
            style={{
              height: "fit-content",
              maxHeight: "400px",
              maxWidth: "400px",
            }}
            src="/assets/logomarenostrum.png"
          />
        </Box>

        <SimpleGrid
          columns={[1, null, 1, 1, 1]}
          spacing="30px"
          mt="15px"
          alignItems="center"
        >
          <Box mx="25px">
            {/* <Box color="#00676E">
            <Heading as="h2" size="xl">
              Artistas de este mes
            </Heading>
          </Box> */}
            {/* <Tabs
              variant="soft-rounded"
              colorScheme="pink"
              border="1px solid white"
              borderRadius="1em"
            >
              <TabList
                mb="1em"
                bg="white"
                borderTopLeftRadius="1em"
                borderTopRightRadius="1em"
                justifyContent="center"
                p="10px"
              >
                <Tab>Abril</Tab>
                <Tab>Mayo</Tab>
              </TabList>
              <TabPanels>
                <TabPanel> */}
            <SimpleGrid
              columns={[1, null, 2, 3, 5]}
              spacing="40px"
              p="10px"
              alignItems="center"
            >
              <SmallTicket
                url="https://javierojeda.com/wp-content/uploads/2021/07/portada-decantando.jpg"
                nombre="Javier Ojeda"
              />
              <SmallTicket
                url="https://lastfm.freetls.fastly.net/i/u/ar0/427cfdea9eeb2e33e6417d82172b3df4"
                nombre="Cigarettes After Sex"
              />
               <SmallTicket
                url="https://chillout.grupodanigarcia.com/assets/images/maria_berasarte_pepe_rivero.jpg"
                nombre="María Berasarte"
              />
              <SmallTicket
                url="https://www.gruporevolver.es/carpetafotos/foto22biografia22Thumb.jpg"
                nombre="Carlos Goñi"
              />
              <SmallTicket
                url="https://pbs.twimg.com/media/FIz5ff9WYAIhLqF?format=jpg&name=large"
                nombre="Efecto Mariposa"
              />
            </SimpleGrid>
            {/* </TabPanel>
                <TabPanel>
                  <SimpleGrid
                    columns={[1, null, 2, 2, 4]}
                    spacing="40px"
                    p="10px"
                  >
                    <SmallTicket
                      url="https://javierojeda.com/wp-content/uploads/2021/07/portada-decantando.jpg"
                      nombre="Javier Ojeda"
                    />
                    <SmallTicket
                      url="https://studiosol-a.akamaihd.net/letras/500x500/fotos/5/c/e/e/5ceeba85520b7fa74e5fe4702c02afb9.jpg"
                      nombre="Cigarettes After Sex"
                    />
                    <SmallTicket
                      url="https://www.gruporevolver.es/carpetafotos/foto22biografia22Thumb.jpg"
                      nombre="Carlos Goñi"
                    />
                    <SmallTicket
                      url="https://pbs.twimg.com/media/FIz5ff9WYAIhLqF?format=jpg&name=large"
                      nombre="Efecto Mariposa"
                    />
                  </SimpleGrid> */}
            {/* </TabPanel>
              </TabPanels>
            </Tabs> */}
          </Box>
          <Box justifyContent={"center"} display="flex">
            {/* <Box justifyContent={"center"} display="flex" marginBottom="10px">
              <Image
                src="https://cdn.wegow.com/media/venue-media/marenostrum-castle-park/marenostrum-castle-park-1576756735.-1x1780.jpg"
                alt="Dan Abramov"
                width="250px"
                height="250px"
              />
            </Box> */}
            <Box
              borderRadius="1em"
              padding="20px"
              color="black"
              bg="white"
              mb="25px"
              mx="25px"
              maxWidth="80%"
            >
              <Text
                fontSize="xl"
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                En Mare Nostrum Unplugged x Bibo se unen dos universos: la oda
                al ritmo y la música de uno de los mejores recintos de
                conciertos de Andalucía, Marenostrum Fuengirola, y el culto al
                sabor de BiBo. El resultado de esta unión es una propuesta de
                ocio única que combina la música más actual con la gastronomía
                del sello Dani García y que promete diversión a raudales.
                <br></br>
                <br></br> Unas actuaciones en acústico “Unplugged” y que serán
                sorpresa “ Secret Sessions”. Nunca sabrás cuándo pero sí quien.
                Esto es Marenostrum Unplugged x Bibo Tarifa presents “The Secret
                Sessions”.
              </Text>
              <Box mt={"35px"}>
                <Link href="https://marenostrumfuengirola.com/" isExternal>
                  ¿Quieres saber mas sobre Marenostrum Fuengirola?
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </Box>
            </Box>
          </Box>
        </SimpleGrid>
      </Box>
    </section>
  );
};

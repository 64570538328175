import {
  Box,
  Flex,
  Grid,
  chakra,
  GridItem,
  Image,
  SimpleGrid,
  useColorModeValue,
  Button,
  Heading,
  Badge,
  Text,
} from "@chakra-ui/react";
import "./ChillOut.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";

import "swiper/css/effect-cards";

import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
  EffectCards,
} from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
export const ChillOut = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    localStorage.setItem("index", 0);
  });
  const [index, setIndex] = useState(0);

  const images = [
    // "/assets/galeria/6F0A3769.jpg",

    // "/assets/galeria/6F0A3772.jpg",
    "/assets/galeria/6F0A3777.png",
    "/assets/galeria/6F0A3781.png",
    "/assets/galeria/6F0A3782.png",
    "/assets/galeria/6F0A3790.png",
    "/assets/galeria/6F0A3801.png",
  ];
  const gafas = [
    {
      imageUrl: "/assets/gafas/MO-SUN-RX-210A-B-GRAL.jpg",
      imageAlt: "mó SUN Rx 210A",
      title: "mó SUN Rx 210A",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre-mujer/mo-sun-rx-210a-negro/43290105.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["pink", "black", "havana"],
    },
    {
      imageUrl: "/assets/gafas/MO-SUN-RX-225A-C-GRAL.png",
      imageAlt: "mó SUN Rx 225A",
      title: "mó SUN Rx 225A",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre-mujer/mo-sun-rx-225a-carey/43290118.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["carey", "red-black"],
    },
    {
      imageUrl: "/assets/gafas/MO-SUN-RX-227M-C-GRAL.png",
      imageAlt: "mó SUN Rx 227M",
      title: "mó SUN Rx 227M",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre-mujer/mo-sun-rx-227m-metalicos/43290123.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["gold", "blue"],
    },
    {
      imageUrl: "/assets/gafas/MO-SUN-RX-239A-C-GRAL.png",
      imageAlt: "mó SUN Rx 239A",
      title: "mó SUN Rx 239A",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre-mujer/mo-sun-rx-239a-carey/44760373.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["black", "carey"],
    },
    {
      imageUrl: "/assets/gafas/mo-sun-rx-285A-B-black.png",
      imageAlt: "mó SUN Rx 285A",
      title: "mó SUN Rx 285A",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre/mo-sun-rx-285a-negro/49970069.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["black"],
    },
    {
      imageUrl: "/assets/gafas/mo-sun-geek-113-M-A-light-gold.png",
      imageAlt: "mó SUN GEEK 113M",
      title: "mó SUN GEEK 113M",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre/mo-sun-geek-113m-metalicos/32191581.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["light-gold", "black"],
    },
    {
      imageUrl: "/assets/gafas/MO-SUN-RX-224M-A-GRAL.png",
      imageAlt: "mó SUN Rx 224M",
      title: "mó SUN Rx 224M",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre-mujer/mo-sun-rx-224m-metalicos/43290113.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["gold", "gun-metal", "brown"],
    },
    {
      imageUrl: "/assets/gafas/mo-SUN-Rx-243A.png",
      imageAlt: "mó SUN Rx 243A",
      title: "mó SUN Rx 243A",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre/mo-sun-rx-243a-carey/45590109.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["black", "grey", "carey"],
    },
    {
      imageUrl: "/assets/gafas/mo-SUN-Rx-284A.png",
      imageAlt: "mó SUN Rx 284A",
      title: "mó SUN Rx 284A",
      formattedPrice: "39 €",
      url: "https://www.multiopticas.com/es/hombre/mo-sun-rx-284a-carey/49970066.html?utm_source=DaniGarcia&utm_medium=webdanigarcia&utm_campaign=bibotarifa&utm_content=-Conversion",
      colors: ["carey", "black"],
    },
  ];
  const [file, setFile] = useState("");
  return (
    <section className="chilloutbackground">
      <Box>
        <Box
          // borderWidth="1px"
          // borderRadius="lg"
          overflow="hidden"
          w="100%"
          bgImage={"/assets/bannerBibo.jpg"}
          color="white"
          height="500px"
          className="shadow"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Image
            w="100%"
            style={{
              height: "fit-content",
              maxHeight: "300px",
              maxWidth: "400px",
            }}
            src="/assets/logobibo.png"
          />
        </Box>
        <Box w="100%" p="40px">
          <Box justifyContent={"center"} display="flex">
            <Box bg="white" borderRadius="1em" p="25px">
              <Heading as="h2" size="xl">
                #playazoBiBo
              </Heading>

              <Text fontSize="lg">
                <strong>BiBo Tarifa</strong> amplía este 2022 su oferta exterior
                con planes únicos y diferentes para que vivas una temporada
                especial y llena de momentos para recordar. Música de la mano de{" "}
                <strong>Mare Nostrum Unplugged x BiBo</strong> y{" "}
                <strong>Nayeli By Wally López</strong>. DJ cada día y muchas
                sorpresas más. Vive el <strong>#playazoBiBo</strong> esta
                temporada.
              </Text>
            </Box>
          </Box>
          <Box w="100%" my="15px">
            <SimpleGrid
              columns={[1, null, 1, 1, 2, 2]}
              spacing="15px"
              justifyContent={"center"}
              alignItems="center"
            >
              {" "}
              <Link
                to="/Marenostrum"
                // maxWidth="50px"
                onClick={() => {
                  localStorage.setItem("index", 1);
                }}
              >
                <Box
                  // borderWidth="1px"
                  // borderRadius="lg"
                  overflow="hidden"
                  w="100%"
                  bgImage={"/assets/bannerMarenostrum.jpg"}
                  color="white"
                  height="150px"
                  className="shadow"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Image
                    w="100%"
                     maxWidth="150px"
                    // height="150px"
                    src="/assets/logomarenostrum.png"
                  />
                </Box>
              </Link>
              <Link
                to="/Wally"
                // maxWidth="50px"
                onClick={() => {
                  // setIndex(2);
                  localStorage.setItem("index", 2);
                }}
              >
                <Box
                  // borderWidth="1px"
                  // borderRadius="lg"
                  overflow="hidden"
                  w="100%"
                  bgImage={"/assets/bannerNayeli.jpg"}
                  color="white"
                  height="150px"
                  className="shadow"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Image
                    w="100%"
                    maxWidth="150px"
                    // height="150px"
                    src="/assets/logoNayeli.png"
                  />
                </Box>
              </Link>
            </SimpleGrid>
          </Box>
          <Box>
            <SimpleGrid
              columns={[1, null, 1, 1, 2, 2]}
              spacing="15px"
              justifyContent={"center"}
              alignItems="center"
            >
              <Box mt="50px" p="20px">
                <SimpleGrid
                  columns={[1, null, 2]}
                  spacing="50px"
                  // <v  justifyContent="center"
                >
                  <chakra.a
                    target="_blank"
                    style={{
                      justifySelf: "center",
                      maxWidth: "660px",
                      width: "100%",
                    }}
                    href="https://grupodanigarcia.com/wp-content/uploads/bibotarifa/chillout/carta-menu-bibotarifa-chillout-ES.pdf"
                  >
                    {" "}
                    <Image
                      w="100%"
                      // maxWidth="150px"
                      // height="150px"
                      src="/assets/botones/btnchillout.png"
                    />
                  </chakra.a>
                  <chakra.a
                    target="_blank"
                    style={{
                      justifySelf: "center",
                      maxWidth: "660px",
                      width: "100%",
                    }}
                    href="https://grupodanigarcia.com/wp-content/uploads/bibotarifa/chillout/carta-bebidas-bibotarifa-chillout.pdf"
                  >
                    {" "}
                    <Image
                      w="100%"
                      // maxWidth="150px"
                      // height="150px"
                      src="/assets/botones/btnbebidas.png"
                    />
                  </chakra.a>
                  <chakra.a
                    target="_blank"
                    style={{
                      justifySelf: "center",
                      maxWidth: "660px",
                      width: "100%",
                    }}
                    href="https://grupodanigarcia.com/wp-content/uploads/bibotarifa/chillout/carta-smoothies-bibotarifa-chillout-ES.pdf"
                  >
                    {" "}
                    <Image
                      w="100%"
                      // maxWidth="150px"
                      // height="150px"
                      src="/assets/botones/btnsmoothies.png"
                    />
                  </chakra.a>
                  <chakra.a
                    target="_blank"
                    style={{
                      justifySelf: "center",
                      maxWidth: "660px",
                      width: "100%",
                    }}
                    href="https://grupodanigarcia.com/wp-content/uploads/bibotarifa/chillout/carta-cocktails-bibotarifa-ES.pdf"
                  >
                    {" "}
                    <Image
                      w="100%"
                      // maxWidth="150px"
                      // height="150px"
                      src="/assets/botones/btncocktails.png"
                    />
                  </chakra.a>{" "}
                </SimpleGrid>
              </Box>

              <Box className="boxPolaroid" mt="20px">
                {" "}
                <Swiper
                  modules={[
                    Navigation,
                    Pagination,
                    Scrollbar,
                    A11y,
                    EffectCards,
                  ]}
                  effect={"cards"}
                  // spaceBetween={50}
                  // slidesPerView={3}
                  // autoplay={true}
                  // navigation
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                  {images.map((image) => {
                    return (
                      <SwiperSlide>
                        <div class="polaroid">
                          <img src={image} alt="image_polaroid" />
                          <div
                            class="caption"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt="logo_BIBO"
                              style={{
                                height: "auto",
                                width: "100px",
                                // height: "fit-content",
                                // maxWidth: "100px",
                              }}
                              src="/assets/logobibo.png"
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Box>
            </SimpleGrid>
          </Box>
          <Box mt="30" w="100%" display="flex" justifyContent="center">
            <video
              width="800px"
              // height="800px"
              controls
              autoplay
              poster="/assets/bibotarifa.png"
            >
              {/* <video width="800px" height="auto" controls> */}
              <source src="assets/video.mp4" type="video/mp4" />
              {/* <source src="movie.ogv" type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
          </Box>
          {/* <Box mt="40px">
            <Heading as="h2" size="2xl">
              MÓ
            </Heading>
          </Box>
          <Box mt="25px" display="flex" justify="center">
            {" "}
            <Swiper
              slidesPerView={2}
              spaceBetween={10}
              slidesPerGroup={2}
              loop={true}
              loopFillGroupWithBlank={true}
              // pagination={{
              //   clickable: true,
              // }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                  slidesPerGroup: 3,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                  slidesPerGroup: 4,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                  slidesPerGroup: 5,
                },
              }}
              // navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {" "}
              {gafas.map((gafa) => {
                return (
                  <SwiperSlide key={gafas.title}>
                    {" "}
                    <chakra.a
                      href={gafa.url}
                      target="_blank"

                      // title="Choc Home Page"
                      // display="flex"
                      // style={{ position: "absolute", left: "15px" }}
                      // alignItems="center"
                    >
                      <Box
                        w="100%"
                        borderWidth="1px"
                        borderRadius="lg"
                        overflow="hidden"
                        bg="white"
                      >
                        <Image src={gafa.imageUrl} alt={gafa.imageAlt} />

                        <Box p="6">
                          <Box
                            mt="1"
                            fontWeight="semibold"
                            as="h4"
                            lineHeight="tight"
                            isTruncated
                          >
                            {gafa.title}
                          </Box>

                          <Box> {gafa.formattedPrice} </Box>
                          <p class="product-colors">
                            {gafa.colors.map((color) => {
                              return <span class={color + " active"}></span>;
                            })}
                          </p>
                        </Box>
                      </Box>
                    </chakra.a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box> */}
        </Box>
      </Box>
    </section>
  );
};

import React from "react";
import "./ticket.css";
const SmallTicket = (props) => {
  return (
    <widget
      type="ticket"
      style={{ alignItems: "center", justifySelf: "center" }}
    >
      <div className="top ticket-flex column">
        <img
          src={props.url}
          style={{ padding: "10px", }}
        />
        <div
          className="deetz ticket-flex row jsb"
          style={{ justifyContent: "center" }}
        >
          <div className="event ticket-flex column">
            <div className="location -bold">{props.nombre}</div>
          </div>
        </div>
      </div>
      <div className="rip"></div>
      <div className="bottom ticket-flex row jsb">
        <div className="barcode"></div>
      </div>
    </widget>
  );
};

export default SmallTicket;

import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import "./firebase.js";
import { Suspense, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  chakra,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ChillOut } from "./Pages/ChillOut/ChillOut";
import { Wallylopez } from "./Pages/Wallylopez/Wallylopez";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaCalendarDay,
} from "react-icons/fa";
import { Marenostrum } from "./Pages/Marenostrum/Marenostum";
// import "./Home.css";
// import { Logo } from "@choc-ui/logo";

import { isMobile } from "react-device-detect";
import { Reservas } from "./Pages/Reservas/Reservas";

function App(props) {
  const [index, setIndex] = useState(
    localStorage.getItem("index") != null ? localStorage.getItem("index") : 0
  );

  const bgColorHeader = () => {
    if (index == 0) {
      return "bibo";
    } else if (index == 1) {
      return "marenostrum";
    } else if (index == 2) {
      return "wally";
    }
  };
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [btnReservas, setBtnReservas] = React.useState(true);
  useEffect(() => {
    console.log("hola");
    setIndex(localStorage.getItem("index"));
  }, [localStorage.getItem("index")]);

  useEffect(() => {
    var URLactual = window.location;
    console.log(URLactual.pathname);
    if (URLactual.pathname == "/Reservas") {
      setBtnReservas(false);
    } else {
      setBtnReservas(true);
    }
  }, [window.location.pathname]);

  return (
    <Suspense fallback="loading">
      <div className="App">
        <React.Fragment>
          <chakra.header
            bg="#F5F5F5"
            w="100%"
            px={{ base: 2, sm: 4 }}
            py={4}
            position="fixed"
            shadow="md"
            zIndex="999"
          >
            <Flex alignItems="center" justifyContent="space-around" mx="auto">
              <chakra.a
                href="/"
                // title="Choc Home Page"
                display="flex"
                style={{ position: "absolute", left: "15px" }}
                alignItems="center"
              >
                {/* {isMobile ? (
                  <img
                    style={{ height: "50px" }}
                    src={"/assets/gdg-logo-movil.png"}
                  />
                ) : (
                  <img
                    style={{ width: "150px" }}
                    src={"/assets/gdg-logo.png"}
                  />
                )} */}
              </chakra.a>
              <Flex alignItems="center" justifyContent="space-around" mx="auto">
                <HStack spacing={15} alignItems="center">
                  <Link
                    to="/"
                    onClick={() => {
                      setIndex(0);
                      localStorage.setItem("index", 0);
                    }}
                  >
                    {" "}
                    <Image
                      w="100%"
                      maxWidth="50px"
                      src={"/assets/logobibo.png"}
                    />
                  </Link>

                  <Link
                    to="/Marenostrum"
                    maxWidth="50px"
                    onClick={() => {
                      setIndex(1);
                      localStorage.setItem("index", 1);
                    }}
                  >
                    {" "}
                    <Image
                      w="100%"
                      maxWidth="50px"
                      src={"/assets/logomarenostrum.png"}
                    />
                  </Link>

                  <Link
                    to="/Wally"
                    maxWidth="50px"
                    onClick={() => {
                      setIndex(2);
                      localStorage.setItem("index", 2);
                    }}
                  >
                    {" "}
                    <Image
                      w="100%"
                      maxHeight="50px"
                      maxWidth="50px"
                      src={"/assets/logoNayeli.png"}
                    />
                  </Link>
                </HStack>
              </Flex>
            </Flex>
          </chakra.header>
        </React.Fragment>
        <Routes>
          <Route path="/" element={<ChillOut />} />
          <Route path="/Marenostrum" element={<Marenostrum />} />
          <Route path="/Wally" element={<Wallylopez />} />
          <Route path="/Reservas" element={<Reservas />} />
        </Routes>
        {/* <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          height="150px"
          scrollBehavior={scrollBehavior}
        >
          <ModalOverlay />
          <ModalContent>
        
            <ModalCloseButton />
            <ModalBody style={{ padding: "40px" }}>
              <iframe
                title="Reservas"
                src="https://www.covermanager.com/reserve/module_restaurant/bibo-tarifa/spanish/?template=trans-black"
                frameborder="0"
                height="2000px"
                width="100%"
              ></iframe>
            </ModalBody>
          </ModalContent>
        </Modal> */}
        <Container
          fluid
          as="footer"
          role="contentinfo"
          bg="#F5F5F5"
          maxWidth="100%"
          py={{ base: "12", md: "16" }}
        >
          <Stack spacing={{ base: "4", md: "5" }}>
            <Stack justify="space-around" direction="row" align="center">
              {/* <Logo /> */}
              <ButtonGroup variant="ghost">
                <IconButton
                  as="a"
                  href="https://es-es.facebook.com/BiBoDaniGarcia/"
                  aria-label="Facebook"
                  icon={<FaFacebook fontSize="1.25rem" />}
                />
                <IconButton
                  as="a"
                  href="https://www.instagram.com/bibodanigarcia/"
                  aria-label="Instagram"
                  icon={<FaInstagram fontSize="1.25rem" />}
                />
                <IconButton
                  as="a"
                  href="https://twitter.com/BiBoDaniGarcia"
                  aria-label="Twitter"
                  icon={<FaTwitter fontSize="1.25rem" />}
                />
              </ButtonGroup>
            </Stack>
            <Text fontSize="sm" color="subtle">
              &copy; {new Date().getFullYear()} Grupo Dani García
            </Text>
          </Stack>
        </Container>
        {btnReservas ? (
          isMobile ? (
            <Link to="/Reservas">
              <Button
                variant="solid"
                className={`float ${bgColorHeader()}`}
                onClick={onOpen}
              >
                <FaCalendarDay />
              </Button>
            </Link>
          ) : (
            <Link to="/Reservas">
              <Button
                leftIcon={<FaCalendarDay />}
                variant="solid"
                className={`float ${bgColorHeader()}`}
                onClick={onOpen}
              >
                ¡Reserva Ya!
              </Button>
            </Link>
          )
        ) : (
          <></>
        )}
      </div>
    </Suspense>
  );
}

export default App;
